.swal2-container.swal2-backdrop-show {
  /* > 1200 */
  z-index: 1300;
}

.swal2-styled:focus,
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus,
.sweet-alert button:focus {
  box-shadow: none !important;
}

div:where(.swal2-container) .swal2-html-container {
  margin: 0 !important;
}

.MuiAutocomplete-endAdornment {
  display: flex;
  height: min(100%, 30px);
}

.MuiAutocomplete-tagSizeSmall {
  max-width: calc(100% - 70px) !important;
  min-width: 60px;
}

.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense .MuiAutocomplete-input {
  padding: 0 !important;
  height: fit-content;
}

.MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiOutlinedInput-marginDense {
  padding: 0 56px 0 8px !important;
  min-height: 32px;
}

.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
  padding: 0 !important;
  display: flex;
  align-items: center;
  align-self: center;
  height: calc(100% + 2px);
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ECEEF0;
}

/* Corner */
::-webkit-scrollbar-corner {
  background: #ECEEF0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #778894; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #506676; 
}