/******* ------------ *******/
/******* basic styles *******/
/******* ------------ *******/
:root {
  --color-gray: #4b4b4b;
  --color-light-gray: #f8f8f8;
  --color-rhi: #0b2b4b;
  --color-rhi-dark: #032140;
  --color-rhi-red: #d33136;
  --color-rhi-green: #239847;
  --color-white: #ffffff;
  --color-white-50: rgba(255, 255, 255, 0.7);
  --color-white-10: rgba(255, 255, 255, 0.1);
  --color-overlay: rgba(63, 71, 78, 0.8);
  --color-darken-5: rgba(11, 43, 75, 0.05);
  --color-darken-1: rgba(11, 43, 75, 0.1);
  --box-shadow: 0 4px 15px var(--color-darken-1);
  --font-family1: 'Neutrif Pro', 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --section-padding-y: 15vh;
  --modules-padding-y: 10vh;
}

@media screen and (min-height: 810px) and (min-width: 39.9376em) {
  :root {
    --section-padding-y: 120px;
    --modules-padding-y: 80px;
  }
}

/* Mobile Screen - Change basix variables */
@media screen and (max-width: 39.9375em) {
  :root {
    --section-padding-y: 50px;
    --modules-padding-y: 30px;
  }
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.lighter {
  font-weight: lighter;
}

.text-xxl {
  font-size: 2em;
}

.text-xl {
  font-size: 1.5em;
}

.text-l {
  font-size: 1.2em;
}

.text-sm {
  font-size: 0.85em;
}

.text-xs {
  font-size: 0.65em;
}

.content {
  color: var(--color-gray);
}

.content a {
  color: var(--color-rhi-red);
}

.content-dark,
.white,
.white *,
.section-head.white * {
  color: var(--color-white);
}

.content-dark a,
.white a,
.white * a,
.section-head.white * a {
  color: var(--color-white);
  text-decoration: underline;
}

footer .content-dark a,
footer .white a,
footer .white * a,
footer .section-head.white * a {
  text-decoration: none;
}

.text-rhi {
  color: var(--color-rhi);
}

.text-red {
  color: var(--color-rhi-red);
}

.opacity-10 {
  opacity: 1;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-0 {
  opacity: 0;
}

.rounded {
  border-radius: 0.65em;
  overflow: hidden;
}

.rounded-1 {
  border-radius: 1em;
  overflow: hidden;
}

.rounded-15 {
  border-radius: 1.5em;
  overflow: hidden;
}

.mg-bottom-0 {
  margin-bottom: 0;
}

.mg-bottom-1 {
  margin-bottom: 1em;
}

.mg-bottom-2 {
  margin-bottom: 2em;
}

.mg-bottom-3 {
  margin-bottom: 3em;
}

.mg-bottom-4 {
  margin-bottom: 4em;
}

.mg-bottom-5 {
  margin-bottom: 5em;
}

.mg-top-0 {
  margin-top: 0;
}

.mg-top-1 {
  margin-top: 1em;
}

.mg-top-2 {
  margin-top: 2em;
}

.mg-top-3 {
  margin-top: 3em;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

/* Text Layout */
@media screen and (min-width: 39.9375em) {
  .content.leftauto {
    column-count: 2;
    column-gap: 3em;
  }
}

.content .text-block {
  margin-top: 2em;
}

.content .text-block.first {
  margin-top: 0;
}

.content .text-block.break-avoid-inside {
  break-inside: avoid;
}

.content .text-block.gray-box {
  background: var(--color-darken-5);
  border-radius: 1em;
  padding: 1em;
  margin: 0 -1em 1em -1em;
  font-size: 0.85em;
}

.content .text-block.gray-box.last {
  margin-bottom: 0;
}

.content .text-block.gray-box > *:last-child {
  margin-bottom: 0;
}

.content .text-block img.full {
  width: 100%;
}

.content .text-block img {
  border-radius: 1em;
}

.mod-text-block .content h4,
.mod-text-block .content h5,
.mod-text-block .content h6 {
  color: var(--color-rhi);
}

body.body-dark-section section.section-dark .mod-text-block .content h4,
body.body-dark-section section.section-dark .mod-text-block .content h5,
body.body-dark-section section.section-dark .mod-text-block .content h6 {
  color: inherit;
}

body,
html {
  overflow-x: hidden;
}

body {
  font-family: var(--font-family1);
  transition:
    background-color 0.5s,
    color 0.5s;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family1);
  font-weight: bold;
  line-height: 1.2em;
}

.h1,
h1 {
  line-height: 1.1em;
  margin-bottom: 0.3em;
}

.more-link {
  color: var(--color-rhi-red);
  font-weight: bold;
}

.more-link:hover,
.more-link:focus {
  color: var(--color-rhi);
}

.more-link.button-white:hover,
.more-link.button-white:focus {
  color: var(--color-white);
  opacity: 0.6;
}

.button,
.input {
  padding: 1em 1.5em;
  border: 0.1rem solid var(--color-rhi-red);
  color: var(--color-rhi-red);
  font-size: 1em;
  background: none;
  border-radius: 100px;
  transition: all 0.2s;
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none !important;
}

.button-white {
  border-color: var(--color-white);
  color: var(--color-white);
}

.button-primary {
  border-color: var(--color-rhi-red);
  color: var(--color-white);
  background: var(--color-rhi-red);
}

.button.button-lg,
.input.input-lg {
  font-size: 1.2em;
}

.input.input-lg {
  height: 60px;
}

.button-secondary {
  border-color: var(--color-rhi);
  color: var(--color-rhi);
}

.button-tertiary {
  border-color: var(--color-gray);
  color: var(--color-gray);
}

.button:hover,
.button:focus,
.button-filled {
  color: var(--color-white);
  background-color: var(--color-rhi-red);
  border-color: var(--color-rhi-red);
}

.button-secondary:hover,
.button-secondary:focus {
  background-color: var(--color-rhi);
  border: 0.1rem solid var(--color-rhi);
}

.button-tertiary:hover,
.button-tertiary:focus {
  background-color: var(--color-gray);
  border: 0.1rem solid var(--color-gray);
}

.button-wrap {
  margin-top: 1em;
}

.button-wrap:first-child {
  margin-top: 0;
}

.more-link.link-arrow::after {
  content: ' ';
  width: 0.5em;
  height: 0.5em;
  border-top: 2px solid var(--color-rhi);
  border-right: 2px solid var(--color-rhi);
  display: inline-block;
  transform: rotate(45deg) translate(10px, -11px);
  margin-left: -0.5em;
}

.more-link.link-arrow.button-white::after {
  border-color: var(--color-white);
}

.input {
  border-color: #fff;
  background: #fff;
  color: var(--color-rhi);
}

.cover {
  background-size: cover;
  background-position: center;
}

.fill-out {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

.career-section-posts .fill-out.pp-text {
  position: relative;
}

.fill-out-hover:hover .fill-out-bg {
  transform: scale(1.2);
  transform: scale(1.2);
  filter: blur(5px);
  opacity: 0.4;
}

.fill-out-hover .fill-out-bg {
  transition: all 2s ease-out;
}

.hero-prehl {
  text-transform: uppercase;
}

/******* ------------ *******/
/******* Main Nav Bar *******/
/******* ------------ *******/
body.nav-floating #mainNavigation {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.5s;
}

#mainNavigation {
  background: var(--color-light-gray);
  margin-top: var(--wp-admin--admin-bar--height);
}

#mainNavigation .nav-container {
  max-width: 1700px;
  margin: auto;
}

#mainNavigation .logo {
  max-width: 140px;
  padding: 1em;
}

#mainNavigation .logo img {
  width: 100%;
}

#mainNavigation ul a {
  color: var(--color-rhi);
}

#mainNavigation .sub-menu {
  position: absolute;
  z-index: 1001;
  background: #fff;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  margin: 0;
  list-style: none;
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 0.7em 0.5em;
  min-width: 260px;
  max-height: calc(100vh - 110px);
  overflow-y: scroll;
}

.dark-hero:not(.nav-floating) #mainNavigation .sub-menu a,
#mainNavigation .sub-menu a {
  color: var(--color-rhi);
}

/*style webkit scroll on sub-menu*/
#mainNavigation .sub-menu::-webkit-scrollbar {
  width: 6px;
}

#mainNavigation .sub-menu::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
}

#mainNavigation .sub-menu:hover::-webkit-scrollbar-thumb {
  background: var(--color-rhi);
  border-radius: 10px;
}

#mainNavigation .menu-item-has-children:hover .sub-menu {
  display: block;
  opacity: 1;
}

#mainNavigation .side-nav {
  max-width: 540px;
}

#mainNavigation .side-nav .side-nav-bottom {
  background: var(--color-darken-5);
  padding: 0.4em 0.2em;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
}

#mainNavigation .side-nav,
#mainNavigation .side-nav .side-nav-bottom {
  margin-top: 0.8em;
}

#mainNavigation .nav-lng-select a {
  color: inherit;
  text-decoration: none;
}

#mainNavigation .nav-lng-select a.selected {
  font-weight: bold;
}

#mainNavigation .social-media-nav img {
  width: 1.8em;
}

#mainNavigation .top-bar-menu-box .menu a {
  padding: 0.7rem 0.5rem 0.7rem 1.2rem;
}

#mainNavigation .menu a:hover,
#mainNavigation .nav-lng-select a:not(.selected):hover,
#mainNavigation .menu a:hover:before {
  color: var(--color-rhi-red);
  border-color: var(--color-rhi-red);
}

#mainNavigation .top-bar-menu-box a::before {
  content: ' ';
  width: 0.5em;
  height: 0.5em;
  border-top: 2px solid var(--color-rhi);
  border-right: 2px solid var(--color-rhi);
  display: inline-block;
  transform: rotate(45deg) translate(-12px, 9px);
  margin-right: -0.5em;
}

#mainNavigation .input-search input[type='text'] {
  margin: 0;
  border-radius: 100px;
}

/*addition career*/
#mainNavigation ul .button {
  background: var(--color-rhi-red);
  margin: 0;
  padding: 0.1em;
}

#mainNavigation ul .button a,
.dark-hero:not(.nav-floating) #mainNavigation ul a,
.dark-hero:not(.nav-floating) #mainNavigation .nav-lng-select {
  color: #fff;
}

.dark-hero:not(.nav-floating) #mainNavigation ul a:hover {
  color: var(--color-rhi-red);
}

.dark-hero:not(.nav-floating) #mainNavigation ul .button:hover {
  background: rgba(255, 255, 255, 0);
  border-color: #fff;
}

.nav-floating #mainNavigation ul .button:hover {
  border-color: var(--color-rhi-red);
}

.nav-floating #mainNavigation ul .button:hover a {
  color: var(--color-rhi-red);
}

.dark-hero:not(.nav-floating) #mainNavigation {
  background: rgba(255, 255, 255, 0);
}

.dark-hero:not(.nav-floating) #mainNavigation .logo {
  filter: saturate(0) brightness(3);
}

.careers-page .main-menu-box {
  min-width: 70%;
}

.overlay-nav header {
  position: absolute;
  width: 100%;
  z-index: 100;
}

.careers-page .side-nav-top {
  gap: 20px;
}

.careers-page #menu-signin a {
  display: flex;
  gap: 10px;
}

/* Language hidden */
.menu-item.only-de {
  display: none;
}

html[lang='de'] .menu-item.only-de {
  display: block;
}

/* Mobile Screen - Hamburger Menu */
@media screen and (max-width: 39.9375em) {
  /* hamburger menu */
  #mainNavigation .main-menu-cell {
    justify-content: flex-end;
  }

  #mainNavigation .hamburger {
    padding: 15px;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  #mainNavigation .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative;
  }

  #mainNavigation .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }

  #mainNavigation .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    width: 2em;
    height: 2px;
    background-color: var(--color-rhi-dark);
    border-radius: 4px;
    position: absolute;
  }

  #mainNavigation .hamburger-inner::before,
  .hamburger-inner::after {
    content: '';
    display: block;
  }

  #mainNavigation .hamburger-inner::before {
    top: -10px;
  }

  #mainNavigation .hamburger-inner::after {
    bottom: -10px;
  }

  #mainNavigation .main-menu-box {
    text-align: right;
  }

  #mainNavigation .main-menu-box.open .hamburger {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 99;
  }

  #mainNavigation .main-menu-box.open .hamburger .hamburger-inner {
    transform: rotate(45deg);
  }

  #mainNavigation .main-menu-box.open .hamburger .hamburger-inner::before {
    top: 0;
    opacity: 0;
  }

  #mainNavigation .main-menu-box.open .hamburger .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
  }

  .dark-hero:not(.nav-floating) #mainNavigation ul a,
  .dark-hero:not(.nav-floating) #mainNavigation .nav-lng-select {
    color: var(--color-rhi);
  }

  .dark-hero:not(.nav-floating) #mainNavigation ul .button:hover {
    background: rgba(255, 255, 255, 0);
    border-color: #fff;
  }

  #mainNavigation #menu-menu-career-refresh {
    display: none;
  }

  #mainNavigation #menu-menu-career-refresh-1 {
    text-align: center;
  }

  .mobile-menu-bottom .mobile-extra {
    margin-top: 30px;
  }

  .mobile-menu-bottom .button {
    color: #fff;
  }

  /* Mobile Menu */
  @keyframes showmenu {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes showmenusub {
    from {
      top: -50px;
    }

    to {
      top: 0;
    }
  }

  #mainNavigation .main-menu-box.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f4f5f5;
    z-index: 98;
    padding: 4em 1em 2em;
    overflow-y: scroll;
  }

  #mainNavigation .main-menu-box .menu,
  #mainNavigation .main-menu-box > .mobile-menu-top,
  #mainNavigation .main-menu-box > .mobile-menu-bottom {
    display: none;
  }

  #mainNavigation .main-menu-box.open {
    animation: showmenu 0.3s 1 forwards;
    display: block;
  }

  #mainNavigation .main-menu-box.open .menu {
    display: block;
  }

  #mainNavigation .main-menu-box.open .menu > li,
  #mainNavigation .main-menu-box.open .mobile-menu-top,
  #mainNavigation .main-menu-box.open .mobile-menu-bottom {
    top: -50px;
    animation: showmenusub 0.5s 1 forwards;
    position: relative;
    display: block;
  }

  #mainNavigation .main-menu-box .menu > li,
  #mainNavigation .main-menu-box .mobile-extra {
    border-top: 1px solid var(--color-darken-5);
    padding: 0.3em 0;
  }

  #mainNavigation .main-menu-box .mobile-extra.menu-top-bar-menu {
    padding: 0;
  }

  #mainNavigation .main-menu-box .mobile-extra.menu-lng {
    padding: 1em 0;
  }

  #mainNavigation .main-menu-box .mobile-extra.menu-lng a {
    margin: 0 0.5em;
    padding: 0.2em 0.3em 0.35em 0.3em;
  }

  #mainNavigation .main-menu-box .mobile-extra.menu-lng a.selected {
    background: var(--color-rhi);
    color: #fff !important;
    border-radius: 5px;
  }

  #mainNavigation .main-menu-box .menu > li:hover {
    background: var(--color-darken-5);
  }

  #mainNavigation .main-menu-box .menu li a:hover {
    color: var(--color-rhi-red);
  }

  #mainNavigation .main-menu-box .menu > li:first-child {
    border-top: none;
  }

  #mainNavigation .main-menu-box .menu li > a {
    padding: 0.8em 1em;
  }

  #mainNavigation .main-menu-box .menu > li > a {
    font-size: 1.2em;
  }

  #mainNavigation .main-menu-box.open .sub-menu {
    position: relative;
    z-index: auto;
    background: none;
    box-shadow: none;
    margin: 0;
    padding: 0 0 0 1.5em;
    display: block;
    opacity: 1;
  }
}

@media screen and (min-width: 39.9375em) and (max-width: 1550px) {
  /*small desktop header layout*/
  #mainNavigation li.menu-item-has-children {
    position: relative;
  }

  #mainNavigation .sub-menu {
    right: 0;
  }

  #mainNavigation .main-mavs > .cell,
  #mainNavigation .side-nav-top > .cell {
    flex: auto;
  }

  #mainNavigation .main-mavs {
    flex-direction: row-reverse;
    align-items: center;
    align-self: center;
  }

  #mainNavigation .main-mavs .main-menu-cell {
    order: 2;
    margin: 0.5em 0 1em 0;
  }

  #mainNavigation .main-mavs .side-nav {
    order: 1;
    margin-top: 0;
    max-width: none;
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }

  #mainNavigation .main-mavs .side-nav .side-nav-top {
    width: 420px;
  }

  #mainNavigation .main-mavs .side-nav .side-nav-bottom {
    margin: 0 0 5px 1em;
    border-radius: 0;
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}

@media screen and (min-width: 39.9375em) and (max-width: 1200px) {
  /*even smaller desktop header layout*/
  #mainNavigation .side-nav-top .nav-lng-select b {
    display: none;
  }

  #mainNavigation .main-mavs .side-nav .side-nav-top {
    width: 310px;
  }
}

/****** -------------- ******/
/****** Start Elements ******/
/****** -------------- ******/
#section-hero.hero-slider-new {
  height: 700px;
}

.hero-slider-new .grid-container,
.hero-slider-new .slider-content-box {
  position: relative;
  height: 100%;
  color: var(--color-rhi);
}

.hero-slider-new .slider-buttons {
  margin-top: 2em;
}

.hero-slider-new .swiper-nav-button {
  opacity: 0;
  filter: brightness(4.5) saturate(0);
  transition: all 0.3s;
}

@media screen and (min-width: 900px) {
  .hero-slider-new:hover .swiper-nav-button {
    opacity: 1;
  }

  .hero-slider-new .swiper-nav-button:hover {
    filter: brightness(0.9) saturate(1);
  }
}

.hero-slider-new .button {
  padding: 1em 1.5em;
  border: 0.1rem solid var(--color-rhi-red);
  color: var(--color-rhi-red);
  font-size: 1.2em;
  background: none;
  border-radius: 100px;
  transition: all 0.2s;
}

.hero-slider-new .button-secondary {
  border-color: var(--color-rhi);
  color: var(--color-rhi);
}

.hero-slider-new .button:hover {
  color: var(--color-white);
  background-color: var(--color-rhi-red);
}

.hero-slider-new .button-secondary:hover {
  background-color: var(--color-rhi);
}

.hero-slider-new .swiper-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  mask-image: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/slider-mask.svg);
  mask-position: center bottom;
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-image: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/slider-mask.svg);
  -webkit-mask-position: center bottom;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  background-position: center;
}

.hero-slider-new .swiper-bg-image div.slider-bg-image,
.hero-slider-new .swiper-bg-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-slider-new .swiper-bg-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-slider-new .swiper-bg-image .mobile-slider-image {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.hero-slider-new .slider-content-box {
  z-index: 2;
}

.hero-slider-new .swiper-pagination-bullet-active {
  background-color: var(--color-rhi);
}

/* Mobile Screen - Hamburger Menu */
@media screen and (max-width: 39.9375em) {
  #section-hero.hero-slider-new {
    min-height: 60vh;
    height: calc(100vh - 100px);
  }

  #section-hero.hero-slider-new .slider-content-box {
    align-items: end !important;
  }

  #section-hero.hero-slider-new .slider-content-box .cell {
    color: #fff;
    padding: 20px;
    background-color: rgba(3, 33, 64, 0.6);
    box-sizing: border-box;
    border-top-right-radius: 2em;
    margin-left: -0.66rem;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }

  /*.hero-slider-new .swiper-bg-image{width:90%;mask-position:center top;-webkit-mask-position:center top;}*/
  .hero-slider-new .swiper-bg-image {
    width: 100%;
    mask: none;
    -webkit-mask: none;
  }
}

/* section general */
section {
  padding: var(--section-padding-y) 0;
  position: relative;
}

section.p-top-0 {
  padding-top: 0;
}

section.p-bottm-0 {
  padding-bottom: 0;
}

section.start-section .section-head {
  color: var(--color-rhi);
}

section .pp-box h2 {
  color: var(--color-rhi);
}

.section-head h2,
.content-wysiwyg h2,
.content-wysiwyg h3,
.content-wysiwyg h4 {
  color: var(--color-rhi);
}

.section-head h2 span {
  color: var(--color-rhi-red);
}

.content-wysiwyg h2 span {
  color: var(--color-rhi-red);
}

.fact-box h3 {
  color: var(--color-rhi-red);
}

/* NOTE: background effect ON - start */
body.body-dark-section section.section-dark .section-head,
.section-head.white,
body.body-dark-section section.section-dark .content {
  color: var(--color-white);
}

body.body-dark-section section.section-dark,
body.body-dark-section section.section-dark .section-head h2 {
  color: var(--color-white) !important;
}

body.body-dark-section {
  background-color: var(--color-rhi);
}

/* NOTE: background effect ON - end */
/* NOTE: background effect OFF - end */
/* section.section-dark .section-head{color:var(--color-white);}
section.section-dark {background-color: var(--color-rhi) !important;color:var(--color-white)  !important;} */
/* NOTE: background effect OFF - end */
/* mouled general */
.sec-module {
  padding-top: var(--modules-padding-y);
  position: relative;
}

.sec-module.modulenum-1 {
  padding-top: 0;
}

/* section animations */
@keyframes slidein {
  from {
    opacity: 0;
    transform: translatex(100px);
  }

  to {
    opacity: 1;
    transform: translatex(0);
  }
}

section .section-head,
section .pp-box {
  position: relative;
}

section.section-active:not(.section-past) .section-head {
  animation: slidein 1s 1 forwards;
}

#section-contents.section-active:not(.section-past) .pp-box {
  animation: slidein 1s 1 forwards;
  position: relative;
}

/* section gallery animations */
@keyframes slidein2 {
  from {
    opacity: 0;
    transform: translatey(50px);
  }

  to {
    opacity: 1;
    transform: translatey(0);
  }
}

section.section-active + section:not(.section-past) .col-gallery-wrap figure {
  opacity: 0;
  transform: translatey(50px);
}

section.section-active:not(.section-past) .col-gallery-wrap figure {
  animation: slidein2 1s ease 2.5s 1 forwards;
}

section.section-active:not(.section-past) .col-gallery-wrap .gallery-image-num-0 figure {
  animation: slidein2 1s ease 1s 1 forwards;
}

section.section-active:not(.section-past) .col-gallery-wrap .gallery-image-num-1 figure {
  animation: slidein2 1s ease 1.3s 1 forwards;
}

section.section-active:not(.section-past) .col-gallery-wrap .gallery-image-num-2 figure {
  animation: slidein2 1s ease 1.6s 1 forwards;
}

section.section-active:not(.section-past) .col-gallery-wrap .gallery-image-num-3 figure {
  animation: slidein2 1s ease 1.9s 1 forwards;
}

section.section-active:not(.section-past) .col-gallery-wrap .gallery-image-num-4 figure {
  animation: slidein2 1s ease 2.2s 1 forwards;
}

/* start post grid slider */
#section-post-grid {
  background:
    url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) -5% top no-repeat,
    url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-hexagon-bg-shade.svg) 110% bottom no-repeat;
  background-size: 30%, 40%;
}

#section-post-grid-bulletin {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) 110% top
    no-repeat;
  background-size: 40%;
}

#section-post-grid .swiper-container,
#section-post-grid-bulletin .swiper-container {
  margin: 0 -20px;
  padding: 60px 40px 80px 40px;
}

#section-post-grid .swiper-button-next,
#section-post-grid .swiper-button-prev,
.rhim-slider .swiper-button-next,
.rhim-slider .swiper-button-prev {
  filter: brightness(0.9) saturate(1);
}

@media screen and (min-width: 900px) {
  /*check slider js for breakpoints */
  #section-post-grid .swiper-slide {
    transition: transform 0.5s ease-out;
    transform: translateY(1px);
  }

  #section-post-grid .swiper-slide-next {
    transform: translateY(60px);
  }

  #section-post-grid .swiper-slide-next + .swiper-slide {
    transform: translateY(-30px);
  }

  #section-post-grid .swiper-container,
  #section-post-grid-bulletin .swiper-container {
    margin: 0 -60px;
    padding: 60px 60px 100px 60px;
  }

  #section-post-grid .swiper-button-next,
  #section-post-grid .swiper-button-prev,
  .rhim-slider .swiper-button-next,
  .rhim-slider .swiper-button-prev {
    filter: brightness(4.5) saturate(0);
    transition: filter 0.1s;
  }

  #section-post-grid .swiper-button-next:hover,
  #section-post-grid .swiper-button-prev:hover,
  .rhim-slider .swiper-button-next:hover,
  .rhim-slider .swiper-button-prev:hover {
    filter: brightness(0.9) saturate(1);
  }
}

@media screen and (max-width: 39.9375em) {
  #section-post-grid {
    background-position:
      -5% 5vh,
      110% bottom;
    background-size: 80%, 90%;
  }
}

#section-post-grid .pp-date {
  padding: 0.5em 0.8em;
  background: var(--color-white);
  display: inline-block;
  border-radius: 100px;
  line-height: 1.2em;
  position: absolute;
  margin: -2.7em 0 0 -0.8em;
  font-size: 0.85em;
  color: var(--color-gray);
  box-shadow: var(--box-shadow);
}

#section-post-grid h4 {
  color: var(--color-rhi);
  font-weight: 600;
  margin-bottom: 0.7em;
}

.post-preview {
  display: block;
  border-radius: 1.5em;
  overflow: hidden;
  box-shadow: 0 1em 2em rgba(0, 0, 0, 0);
  transition: box-shadow 0.2s;
  background: #fff;
  position: relative;
}

.pp-text {
  padding: 1.5em 1.8em;
}

.pic-category {
  display: none;
}

.swiper-slide-active .post-preview,
.swiper-slide-active + .swiper-slide .post-preview,
.swiper-slide-active + .swiper-slide + .swiper-slide .post-preview,
.post-type-archive-career-blog .post-preview {
  box-shadow: var(--box-shadow);
}

/* general slider */
.rhim-slider .pp-pic {
  padding: 1em 1.8em;
}

.rhim-slider .pp-pic img {
  box-shadow: 0 0 1em #0002;
  border-radius: 1.5em;
}

.rhim-slider .pp-text {
  padding-top: 0;
}

@media screen and (min-width: 900px) {
  .swiper-container.rhim-slider {
    margin: 0 -1.5em;
    padding: 0 1.5em;
  }
}

.rhim-slider.bg-img-style .pp-pic {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.rhim-slider.bg-img-style .swiper-slide {
  height: auto;
}

.rhim-slider.bg-img-style .pp-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rhim-slider.bg-img-style .pp-text {
  z-index: 2;
  position: relative;
  /*! background: blue; */
  height: 100%;
  padding: 1em 1.8em;
  box-sizing: border-box;
}

.slide-wrap {
  height: 100%;
}

.pp-wrap {
  height: 100%;
}

.rhim-slider.bg-img-style .pp-text-inner {
  padding: 1.5em 1.3em 1em;
  border-radius: 1.5em;
  height: 100%;
  background: rgba(11, 43, 75, 0.75);
}

.rhim-slider.bg-img-style .pp-text-inner,
.rhim-slider.bg-img-style .pp-text-inner .content {
  color: #fff;
}

/* start innovation slider */
#section-innovation {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-hexagon-bg-shade.svg) 110% bottom
    no-repeat;
  background-size: 40%;
}

#section-innovation .swiper-container {
  margin: 0 -20px;
}

#section-innovation .swiper-wrapper {
  padding: 0;
}

#section-innovation .swiper-container {
  padding: 100px 50px;
}

#section-innovation .swiper-button-next,
#section-innovation .swiper-button-prev {
  filter: brightness(0.9) saturate(1);
}

#section-innovation .swiper-button-next,
#section-innovation .swiper-button-prev {
  display: none;
}

@media screen and (min-width: 900px) {
  /*check slider js for breakpoints */
  #section-innovation .swiper-slide {
    transition: transform 0.5s ease-out;
    transform: translateY(1px);
  }

  #section-innovation .swiper-slide-next {
    transform: translateY(60px);
  }

  #section-innovation .swiper-slide-next + .swiper-slide {
    transform: translateY(-30px);
  }

  #section-innovation .swiper-wrapper {
    margin: 0 -50px;
    padding: 20px;
  }

  #section-innovation .swiper-container {
    margin: 0 -60px;
    padding: 60px 60px 100px 60px;
  }
}

@media screen and (max-width: 39.9375em) {
  #section-innovation {
    background-size: 80%;
  }
}

#section-innovation .pp-date {
  padding: 0.5em 0.8em;
  background: var(--color-white);
  display: inline-block;
  border-radius: 100px;
  line-height: 1.2em;
  position: absolute;
  margin: -2.7em 0 0 -0.8em;
  font-size: 0.85em;
  color: var(--color-gray);
  box-shadow: var(--box-shadow);
}

#section-innovation h4 {
  color: var(--color-rhi);
  font-weight: 600;
  margin-bottom: 0.7em;
}

#section-innovation .post-preview {
  overflow: visible;
}

#section-innovation .pic-category {
  text-align: center;
}

#section-innovation .post-preview {
  box-shadow: var(--box-shadow);
}

#section-innovation .post-preview .pp-pic {
  padding: 0 5%;
  margin-bottom: -1.8em;
  text-align: center;
}

#section-innovation .post-preview .pp-pic img {
  position: relative;
  top: -40px;
  border-radius: 20px;
  transition: top 0.3s;
}

#section-innovation a.post-preview:hover .pp-pic img {
  top: -50px;
}

/* start facts & figures */
#section-facts {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-red.svg) 110% 90% no-repeat;
  background-size: 40% auto;
}

#section-facts .fact-box {
  padding: 1.2rem;
  border-radius: 1.5rem;
  border: 1px solid var(--color-white-50);
  position: relative;
  height: calc(100% - 2rem);
  box-shadow: 0 0 0 1px var(--color-rhi);
}

#section-facts .fact-hl-wrap {
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  margin-top: 2rem;
}

#section-facts .fact-hl {
  font-size: 4em;
  line-height: 1em;
  border-bottom: 1px solid var(--color-rhi-red);
  font-family: var(--font-family1);
}

#section-facts .fact-symbol {
  font-size: 2em;
  margin: 0 0 0 0.2em;
  line-height: 1em;
}

#section-facts .fact-content {
  margin-top: 1em;
}

#section-facts .fact-comment {
  height: 1.2rem;
  font-size: 0.85em;
  margin-top: 0.8rem;
  text-align: right;
}

#section-facts .section-head {
  margin-bottom: 2em;
}

#section-facts #stock-info {
  padding: 1em 0;
}

#section-facts #stock-info .value {
  padding: 0.3em 0;
  position: relative;
}

#section-facts #stock-info .value:after {
  position: absolute;
  right: 0;
  font-size: 0.6em;
}

#section-facts #stock-info.positive .value:after {
  content: '\25B2';
}

#section-facts #stock-info .value:after,
#section-facts #stock-info .num {
  color: var(--color-rhi-green);
}

#section-facts #stock-info.negative .value:after,
#section-facts #stock-info.negative .num {
  color: var(--color-rhi-red);
}

#section-facts #stock-info.negative .value:after {
  content: '\25BC';
}

#section-facts #stock-info .info {
  color: var(--color-overlay);
}

#section-facts #stock-info .stock-iframe {
  margin-bottom: 14px;
}

#section-facts .button {
  font-size: 0.85em;
}

#section-facts .cell.main-fact .fact-box {
  box-shadow: none;
}

/* NOTE: background effect ON - start */
#section-facts .cell.main-fact {
  margin-bottom: 2em;
}

body.body-dark-section #section-facts .cell.main-fact,
body.body-dark-section #section-facts .fact-box {
  background: var(--color-white);
  color: var(--color-rhi);
  border-radius: 1.5rem;
  box-shadow: var(--box-shadow);
}

/* NOTE: background effect ON - end */
/* NOTE: background effect OFF - end */
/* #section-facts .cell.main-fact{background: var(--color-white);color:var(--color-rhi);margin-bottom: calc(var(--section-padding-y) * -1);padding-bottom: var(--section-padding-y);border-top-left-radius: 1.5rem;border-top-right-radius: 1.5rem;} */
/* NOTE: background effect OFF - end */
.fact-box {
  padding: 1.2rem;
  border-radius: 1.5rem;
  border: 1px solid var(--color-white-50);
  position: relative;
  height: calc(100% - 2rem);
  box-shadow: 0 0 0 1px var(--color-rhi);
}

.cell.main-fact {
  margin-bottom: 2em;
}

.cell.main-fact,
.fact-box {
  background: var(--color-white);
  color: var(--color-rhi);
  border-radius: 1.5rem;
  box-shadow: var(--box-shadow);
}

.cell.main-fact .fact-box {
  box-shadow: none;
}

/* detail contents */
#section-contents {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) -5% 5vh
    no-repeat;
  background-size: 20% auto;
}

#section-contents .pp-box,
.mod-content-block .pp-box {
  padding: 1.5em;
  position: relative;
  z-index: 2;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-radius: 1.5em;
  background: rgba(255, 255, 255, 0.6);
}

#section-contents .content-image,
.mod-content-block .content-image {
  margin-left: -3em;
  position: relative;
  z-index: 1;
  border-radius: 1.5em;
  overflow: hidden;
}

.preheadline {
  color: var(--color-rhi-red);
  font-weight: bold;
}

@media screen and (max-width: 39.9375em) {
  #section-contents {
    background-size: 60% auto;
  }

  #section-contents .content-image,
  .mod-content-block .content-image {
    margin: 0 2em -2em -3em;
  }

  #section-contents .pp-box,
  .mod-content-block .pp-box {
    box-shadow: var(--box-shadow);
  }
}

/* start indutries */
#section-industries {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-hexagon-bg-shade-left.svg)-10% top
    no-repeat;
  background-size: 40% auto;
}

/* #section-industries {background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-img1.png) -15% 90% no-repeat, url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-hexagon-bg-shade-left.svg)-10% top no-repeat; background-size: 40%,40%;} */
.industries-grid .post-preview {
  box-shadow: var(--box-shadow);
}

.industries-grid .pp-box-wrap {
  display: block;
  padding-top: 100%;
  overflow: hidden;
  position: relative;
}

.industries-grid .pp-box-wrap .pp-box,
.industries-grid .pp-box-wrap .pp-box > a,
.industries-grid .pp-box-wrap .pp-box .pp-box-title {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.industries-grid .pp-box-wrap .pp-box,
.industries-grid .pp-box-wrap .pp-box > a,
.industries-grid .pp-box-wrap .pp-box .pp-box-title {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
}

.industries-grid .pp-box-wrap .pp-box.pp-box-overlay {
  background: var(--color-white);
  opacity: 0;
  transition: all 0.3s;
}

/* #section-industries .pp-box-wrap .pp-box.pp-box-overlay {background: rgba(255,255,255,.8);opacity: 0;transition: all .3s;margin: 10px;width: calc(100% - 20px);height: calc(100% - 20px);border-radius: 1em;backdrop-filter: blur(10px);} */
.industries-grid .pp-box-wrap:hover .pp-box.pp-box-overlay {
  opacity: 1;
}

.industries-grid .pp-box-wrap .pp-box-title {
  background-image: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
}

.industries-grid .pp-box-wrap h4 {
  font-size: 2em;
  font-weight: bold;
  color: var(--color-white);
  line-height: 1em;
}

.industries-grid .pp-box-wrap .pp-pic-overlay {
  padding: 1.5em;
}

.industries-grid .pp-box-wrap a,
#section-industries .pp-box-wrap a:hover {
  color: var(--color-gray);
}

.industries-grid .pp-box-wrap .pp-pic-overlay h4 {
  color: var(--color-rhi-red);
}

#section-industries .industries-grid {
  padding: 2rem 10vw;
}

.bg-grid-container .industries-grid {
  max-width: 300px;
  margin: 0 auto;
}

@media screen and (min-width: 64em) {
  .bg-grid-container .industries-grid {
    max-width: none;
  }

  #section-industries .industries-grid,
  .bg-grid-container .industries-grid {
    padding: var(--section-padding-y) 5vw;
  }

  #section-industries .industries-grid .row-nth-2,
  .bg-grid-container .industries-grid .row-nth-2 {
    transform: translateY(4em);
  }

  #section-industries .industries-grid .row-nth-3,
  .bg-grid-container .industries-grid .row-nth-3 {
    transform: translateY(-3em);
  }
}

#section-industries .grid-container {
  position: relative;
  z-index: 2;
}

#section-industries .industries-bg {
  display: block;
  width: 45%;
  position: absolute;
  bottom: 15vh;
  left: -6%;
  background-image: url(https://www.rhimagnesita.com/wp-content/uploads/2022/09/breitenau-basic-46-bg.jpg);
  background-size: auto 100vh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  mask-image: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-img1.png);
  mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-image: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-img1.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;
  z-index: 1;
}

#section-industries .industries-bg img {
  width: 100%;
  display: block;
  visibility: hidden;
  opacity: 0;
}

@media screen and (max-width: 39.9375em) {
  #section-industries .industries-bg {
    bottom: 0;
    left: 35%;
    width: 80%;
  }
}

/* quote */
/* #section-quotes {background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-red.svg) 110% var(--section-padding-y) no-repeat; background-size: 40% auto;} */
#section-quotes .section-head,
.bg-story-slider .section-head {
  display: flex;
  margin-bottom: 2em;
}

#section-quotes .headline-icon,
.bg-story-slider .headline-icon {
  margin: 0 1.5em;
}

#section-quotes .pp-box-wrap,
.bg-story-slider .pp-box-wrap {
  display: block;
  padding-top: 140%;
  overflow: hidden;
  position: relative;
  border-radius: 1em;
}

#section-quotes .pp-box-wrap .pp-box,
#section-quotes .pp-box-wrap .pp-box .pp-box-title,
.bg-story-slider .pp-box-wrap .pp-box,
.bg-story-slider .pp-box-wrap .pp-box .pp-box-title {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#section-quotes .pp-box-wrap .pp-box.pp-box-overlay,
.bg-story-slider .pp-box-wrap .pp-box.pp-box-overlay {
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: all 0.3s;
  margin: 0.5em;
  width: calc(100% - 1em);
  height: calc(100% - 1em);
  border-radius: 0.7em;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  font-size: 15px;
  line-height: 1.3em;
  overflow: hidden;
}

#section-quotes .pp-box-wrap .pp-box.pp-box-overlay,
#section-quotes .pp-box-wrap .pp-box.pp-box-overlay .content,
.bg-story-slider .pp-box-wrap .pp-box.pp-box-overlay,
.bg-story-slider .pp-box-wrap .pp-box.pp-box-overlay .content {
  color: var(--color-white);
}

#section-quotes .pp-box-wrap:hover .pp-box.pp-box-overlay,
.bg-story-slider .pp-box-wrap:hover .pp-box.pp-box-overlay {
  opacity: 1;
}

#section-quotes .pp-box-wrap:hover .pp-box .pp-box-title,
.bg-story-slider .pp-box-wrap:hover .pp-box .pp-box-title {
  opacity: 0;
}

#section-quotes .pp-box-wrap .pp-pic-overlay,
.bg-story-slider .pp-box-wrap .pp-pic-overlay {
  padding: 1.5em;
}

#section-quotes .pp-box-wrap .quote-icon,
.bg-story-slider .pp-box-wrap .quote-icon {
  width: 1.5em;
}

#section-quotes .pp-box-wrap .pp-pic-overlay .name,
.bg-story-slider .pp-box-wrap .pp-pic-overlay .name {
  margin-top: 1em;
}

#section-quotes .pp-box-wrap .pp-pic-overlay .name,
.bg-story-slider .pp-box-wrap .pp-pic-overlay .name {
  margin-top: 1em;
  font-weight: bold;
}

#section-quotes .pp-box-wrap .pp-pic-overlay .position,
.bg-story-slider .pp-box-wrap .pp-pic-overlay .position {
  font-weight: normal;
  opacity: 0.7;
}

#section-quotes .pp-box-title,
.bg-story-slider .pp-box-title {
  text-shadow: 0 2px 7px #000;
}

@media screen and (max-width: 540px) {
  #section-quotes .grid-margin-x > .small-6 {
    width: 240px;
  }

  #section-quotes .quote-wrapper {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  #section-quotes .quote-container {
    overflow-x: scroll;
  }
}

.mod-quotes .story-text-wrap .name {
  margin-top: 1em;
  font-weight: bold;
}

.mod-quotes .story-text-wrap .position {
  font-weight: normal;
  opacity: 0.7;
}

.bg-story-slider .pp-box-wrap {
  background: #fff;
  box-shadow: 0 0 10px #0002;
  max-width: 300px;
  width: 100%;
}

.bg-story-slider .swiper-slide {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-story-slider .no-pic + .pp-box-overlay {
  opacity: 1 !important;
  background-color: var(--color-rhi) !important;
}

.bg-story-slider .no-pic {
  opacity: 0;
}

@media screen and (max-width: 540px) {
  .bg-story-slider .pp-box-wrap {
    padding-top: 100%;
  }
}

/* footer */
footer.rf22 {
  padding: 10vh 0;
  background: var(--color-rhi-dark);
}

footer.rf22 .prefooter {
  background: var(--color-rhi-dark);
}

footer.rf22 .prefooter + .mainfooter {
  margin-top: 10vh;
  padding-top: 5vh;
  border-top: 1px solid #fff;
}

footer.rf22 .prefooter .prefooter-figure {
  background-image: url(../../../assets/images/home/rhi-footer-bg-1.jpg);
  background-attachment: fixed;
  background-size: 100% auto;
  background-position: left bottom;
  mask-image: url(../../../assets/images/home/rhi-logo-bg-img1.png);
  mask-repeat: no-repeat;
  mask-size: contain;
  -webkit-mask-image: url(../../../assets/images/home/rhi-logo-bg-img1.png);
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;
}

footer.rf22 .prefooter .prefooter-figure img {
  visibility: hidden;
}

@media screen and (max-width: 64em) {
  footer.rf22 .prefooter .prefooter-claim {
    font-size: 5vw;
  }
}

footer.rf22 .footer-menu-logo #logocolor,
footer.rf22 .footer-social-media #fillcolor {
  fill: #fff;
}

footer .mainfooter ul a {
  color: var(--color-white);
}

footer .mainfooter ul a:hover {
  color: var(--color-white-50);
}

footer #rhi_logo {
  width: 100%;
  max-width: 140px;
  height: auto;
}

/****** ---------------- ******/
/****** Careers Elements ******/
/****** ---------------- ******/
#section-career-hero {
  background: #000;
  min-height: 90vh;
}

#section-career-hero.small-hero {
  min-height: 60vh;
  padding: calc(var(--section-padding-y) + 90px) 0;
}

section.bg-section {
  position: relative;
}

section.bg-section .section-bg-image.darken {
  opacity: 0.4;
}

section.bg-section > .section-bg-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-position: center;
}

section.bg-section > * {
  z-index: 2;
}

section.bg-section .section-bg-image video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

section.bg-section .section-bg-image .mobile-slider-image {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.careers-page #mainNavigation .side-nav,
.sustainability-page #mainNavigation .side-nav {
  margin: 0;
}

.careers-page #mainNavigation .main-mavs .side-nav .side-nav-top {
  width: auto;
}

.careers-page .page-title {
  padding: 3rem 5rem;
  display: inline-block;
  margin: -3rem;
  background: white;
  z-index: 1;
  position: relative;
  border-radius: 4rem;
}

.careers-page .page-title h1 {
  padding: 0;
  margin: 0;
  color: var(--color-rhi);
}

.post-type-archive-career-blog .archive-grid-section {
  padding-top: 0;
}

.post-type-archive-career-blog .archive-grid-wrap {
  margin-top: -90px;
  z-index: 2;
  position: relative;
}

img.career-blog-portrait {
  display: block;
  border-radius: 1.5em;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  position: relative;
  margin-top: calc(var(--section-padding-y) * -1 - 60px);
  z-index: 2;
}

#section-facts.career-section {
  background-position: -10% -10%;
  background-size: 30% auto;
}

#section-facts .fact-hl {
  font-size: 3em;
}

/* NOTE: background effect ON - start */
#section-facts.career-section .cell.main-fact {
  margin-bottom: 2em;
}

body.body-dark-section #section-facts.career-section .cell.main-fact,
body.body-dark-section #section-facts.career-section .fact-box {
  background: none;
  color: #fff;
}

/* NOTE: background effect ON - end */
.cell.tile,
.section-head .content blockquote {
  padding: 1.3em;
  background-color: var(--color-rhi-dark);
  border-radius: 1.5em;
  border: none;
}

.cell.tile > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.cell.tile > *:last-child,
.section-head .content blockquote p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.cell.faded,
.section-head .content blockquote,
.section-head .content blockquote * {
  color: rgba(255, 255, 255, 0.7);
}

.career-section.career-section-posts .post-preview {
  min-height: 350px;
  height: 100%;
  background-color: var(--color-rhi);
}

.career-section.career-section-posts .pp-text {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  padding: 2em;
}

.career-section.career-section-posts .pp-content {
  flex-grow: 1;
}

.career-section.career-industries-slider .post-preview {
  min-height: 250px;
  background-color: var(--color-rhi);
}

.career-section.career-industries-slider .pp-text {
  display: flex;
  align-items: center;
}

.career-section.career-industries-slider .pp-text:hover * {
  color: white;
  opacity: 0.6;
  transition: all 0.2s;
}

.swiper-container.control-arrows-aside .swiper-button-next,
.swiper-container.control-arrows-aside .swiper-button-prev {
  filter: brightness(0.9) saturate(1);
}

@media screen and (min-width: 900px) {
  /*check slider js for breakpoints */
  .career-ind-slider {
    padding: 0 60px;
  }

  .swiper-container.control-arrows-aside {
    margin: 0 -60px;
    padding: 0 60px;
  }

  .swiper-container.control-arrows-aside .swiper-button-next,
  .swiper-container.control-arrows-aside .swiper-button-prev {
    filter: brightness(4.5) saturate(0);
    transition: filter 0.1s;
  }

  .swiper-container.control-arrows-aside .swiper-button-next:hover,
  .swiper-container.control-arrows-aside .swiper-button-prev:hover {
    filter: brightness(0.9) saturate(1);
  }
}

.job-tiles a.job-item {
  display: block;
  background: var(--color-light-gray);
  padding: 1em 1.5em;
  border-radius: 1em;
  position: relative;
  transition: transform 0.2s;
}

.job-tiles a.job-item:hover {
  transform: scale(1.02) translateY(-5px);
}

.job-tiles a.job-item .job-header {
  display: flex;
  justify-content: space-between;
}

.job-tiles a.job-item .job-title {
  font-weight: bold;
  color: var(--color-rhi);
}

.job-tiles a.job-item .job-location {
  color: var(--color-rhi-red);
}

.job-tiles a.job-item .job-content {
  padding: 0.5em;
  background: #fff;
  border-radius: 0.6em;
  margin: 1em 0 0.5em 0;
  color: var(--color-rhi-dark);
}

.job-tiles a.job-item .job-icon {
  position: absolute;
  top: -1em;
  right: 1.5em;
}

.career-application-process .timeline-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.career-application-process .timeline-wrap::before {
  display: block;
  height: 3px;
  background: #cbdae9;
  content: ' ';
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 27px;
}

.career-application-process .timeline-icon {
  position: relative;
}

.career-application-process .timeline-icon,
body.body-dark-section section.section-dark .timeline-icon span {
  color: var(--color-rhi);
}

body.body-dark-section section.section-dark .timeline-icon {
  color: var(--color-white);
}

.career-application-process .active .timeline-icon,
body.body-dark-section section.section-dark .active .timeline-icon {
  color: var(--color-rhi-red);
}

.career-application-process .timeline-icon.color-tl-1 {
  color: var(--color-rhi);
}

.career-application-process .timeline-icon.color-tl-2 {
  color: var(--color-rhi-red);
}

.career-application-process .timeline-icon.color-tl-3 {
  color: #78a0c7;
}

.career-application-process .timeline-icon.color-tl-4 {
  color: #000;
}

.career-application-process .timeline-icon svg {
  width: 50px;
  margin: 0 auto;
  display: block;
  height: 56px;
  fill: currentColor;
}

.career-application-process .timeline-icon span {
  display: block;
  position: absolute;
  top: 0;
  line-height: 56px;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
}

.career-application-process .timeline-step:first-child .timeline-icon:before,
.career-application-process .timeline-step:last-child .timeline-icon:before {
  content: '';
  display: block;
  width: 50%;
  height: 56px;
  position: absolute;
  z-index: -1;
  background-color: #fff;
}

.career-application-process .timeline-step:last-child .timeline-icon:before {
  right: 0;
}

.career-application-process .timeline-hl {
  font-size: 1.2em;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  display: inline-block;
}

.career-application-process label.timeline-hl {
  cursor: pointer;
  border-radius: 0.5em;
  background: #f7f8f900;
  padding: 0.2em 0.8em;
  color: #a9c7e5;
  transition: all 0.2s;
}

.career-application-process label.timeline-hl:hover,
.career-application-process .tab.active label.timeline-hl {
  background: #a9c7e566;
  color: #0a0a0a;
}

.timeline-detail input {
  display: none;
}

.timeline-detail input + {
  display: none;
}

/*.timeline-detail-content {display:none;opacity:0;transition:all .3s;}
.timeline-detail-content.active  {display:block;opacity:1;}*/
.timeline-vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.timeline-vertical > * {
  width: 100%;
}

.timeline-vertical > .timeline-head-col {
  width: 30%;
}

.timeline-vertical > .timeline-detail-col {
  width: 70%;
}

.timeline-vertical .timeline-wrap {
  flex-direction: column;
  gap: 20px;
}

.timeline-vertical .timeline-icon:before {
  display: none !important;
}

.career-application-process.timeline-vertical .timeline-wrap {
  flex-direction: column;
  gap: 20px;
}

.career-application-process.timeline-vertical .timeline-wrap::before {
  height: calc(100% - 56px);
  width: 3px;
  left: calc(50% - 1.5px);
}

@media screen and (max-width: 39.9375em) {
  .career-application-process .timeline-wrap {
    flex-direction: column;
    gap: 20px;
  }

  .career-application-process .timeline-wrap::before {
    height: calc(100% - 56px);
    width: 3px;
    left: calc(50% - 1.5px);
  }

  #section-application-process .timeline-hl {
    text-shadow: 0 0 10px #fff;
  }

  #section-application-process .timeline-step {
    text-align: center;
  }

  .career-flex-steps .timeline-wrap::before,
  .career-application-process .timeline-step:first-child .timeline-icon::before,
  .career-application-process .timeline-step:last-child .timeline-icon::before {
    display: none;
  }

  .career-flex-steps .timeline-hl {
    font-size: 1rem !important;
  }

  .career-flex-steps .timeline-wrap {
    flex-direction: row !important;
    overflow-x: scroll;
    gap: 10px !important;
  }

  .career-flex-steps.timeline-vertical > .timeline-head-col,
  .career-flex-steps.timeline-vertical > .timeline-detail-col {
    width: 100%;
  }
}

.faq-container {
  margin-top: 1em;
  border-top: 1px solid var(--color-rhi);
}

.faq-container:first-child {
  border: none;
}

.faq-container .faq-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  padding: 1.5rem 1rem 0.5rem 1rem;
}

.faq-container .faq-header .hl {
  flex-grow: 1;
  color: var(--color-rhi);
  margin: 0;
}

.faq-container .faq-header .faq-arrow {
  width: 20px;
  height: 20px;
  border-right: 2px solid var(--color-rhi-red);
  border-bottom: 2px solid var(--color-rhi-red);
  transform: rotate(45deg) translate(-4px, -4px);
  margin-top: 5px;
  transition: transform 0.2s;
}

.faq-container .faq-answer {
  padding: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  font-size: 0.01px;
  transition:
    max-height 0.5s,
    padding 0.3s,
    opacity 0.3s;
}

.faq-container .faq-answer > p {
  padding: 0;
  margin: 0;
}

.faq-container input:checked + .faq-header .faq-arrow {
  transform: rotate(225deg) translate(-4px, -4px);
}

.faq-container input:checked + * + .faq-answer {
  padding: 0.5rem 1rem;
  opacity: 1;
  font-size: 1em;
  max-height: 1000px;
  overflow-y: scroll;
}

.faq-container input {
  display: none;
}

.career-story-slider .story-text-wrap {
  padding: 1.5em;
  box-sizing: border-box;
}

.career-story-slider .story-text-wrap .content {
  padding: 0.5em 0;
}

.career-story-slider .quote-icon {
  width: 1.5em;
}

body.body-dark-section section.section-dark .career-story-slider .story-text-wrap * {
  color: var(--color-white);
}

.career-story-slider .story-img {
  border-radius: 20px;
  overflow: hidden;
}

.career-story-slider .story-img,
.career-story-slider .story-img img {
  display: block;
  width: 100%;
}

.career-story-slider div[class='swiper-slide'] .story-img {
  max-height: 400px;
}

.career-story-slider .swiper-button-next.swiper-button-disabled,
.career-story-slider .swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.story-img-wrap {
  max-width: 300px;
}

@media screen and (max-width: 39.9375em) {
  #section-career-stories .story-text-wrap {
    width: 85% !important;
  }

  .story-img-wrap {
    margin: 0 35px -50px auto;
  }

  .faq-container h5.hl {
    max-width: 90%;
  }
}

body.careers-page #section-contents {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) 105% bottom
    no-repeat;
  background-size: 30% auto;
}

body.careers-page #section-application-process,
body.careers-page #section-faq {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) -5% top
    no-repeat;
  background-size: 30% auto;
}

#section-career-stories {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-red.svg) 110% top no-repeat;
  background-size: 20% auto;
}

.section-head .content .alignright {
  float: right;
  margin: 0 -1em 1em 1em;
  border-radius: 1em;
}

.section-head .content .alignleft {
  float: left;
  margin: 0 1em 1em -1em;
  border-radius: 1em;
}

.bg-wrapper {
  padding: 10vh 0;
}

.bg-wrapper.odd,
.bg-wrapper.even + .bg-wrapper {
  background-color: #f8f9fa;
}

.icon-row-has-icon h3 {
  margin-top: 0.5em;
}

/****** ---------------- ******/
/****** Bulletin Elements ******/
/****** ---------------- ******/
#section-bulletin-hero {
  background: #000;
  min-height: 80vh;
}

#section-bulletin-hero.small-hero {
  min-height: 60vh;
}

@media screen and (min-width: 39.9375em) {
  .bulletin-section .content.content-two-column {
    column-count: 2;
    column-gap: 4em;
  }

  /*.bulletin-section .content.content-two-column > *{break-inside: avoid;}*/
}

#section-facts.bulletin-section {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-red.svg) -10% -10%
    no-repeat;
  background-size: 30% auto;
}

#section-contents .content .form-inline {
  padding: 3px;
  border: 1px solid gray;
  border-radius: 20rem;
}

#section-contents .content .form-inline > p {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0;
}

#section-contents .content .form-inline > p > label,
#section-contents .content .form-inline > p input {
  height: 3em;
  padding-left: 1em;
}

#section-contents .content .form-inline input,
#section-contents .content .form-inline input:focus {
  background: none;
  box-shadow: none;
  border: none;
  margin: 0;
}

#section-contents .content .form-inline input[type='submit'] {
  color: var(--color-rhi-red);
  border: 1px solid var(--color-rhi-red);
  padding: 0 2em;
  border-radius: 20rem;
  transition: all 0.2s;
  cursor: pointer;
}

#section-contents .content .form-inline input[type='submit']:hover {
  color: var(--color-white);
  background-color: var(--color-rhi-red);
}

#section-contents .content .form-inline > p > label,
#section-contents .content .form-inline > p input {
  height: 3em;
  padding-left: 1em;
}

#section-contents .content .form-inline > p .wpcf7-spinner {
  display: none;
}

#section-contents .content .wpcf7-form.submitting .form-inline > p > * {
  display: none;
}

#section-contents .content .wpcf7-form.submitting .form-inline > p > .wpcf7-spinner {
  display: block;
}

.breadcrumb-overlay {
  position: absolute;
  bottom: 1rem;
  max-width: 75rem;
  width: 100%;
}

.breadcrumb-overlay .more-link {
  text-decoration: none;
  margin-right: 1em;
  border-right: 1px solid #fff;
  padding-right: 1em;
}

.breadcrumb-overlay .more-link:hover,
.breadcrumb-overlay .more-link:focus {
  color: var(--color-rhi-red);
}

.bulletin-blog-navigation {
  margin: 3rem auto;
}

.bulletin-blog-title h1 {
  margin-bottom: 1em;
  font-size: 3em;
}

.bulletin-blog-preinfo {
  display: flex;
  justify-content: space-between;
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 1em;
  gap: 2rem;
}

.bulletin-info-item.toc {
  min-width: 200px;
}

.bulletin-info-item.toc .toc-header,
.bulletin-info-item.readingtime {
  white-space: nowrap;
}

.bulletin-info-item > svg {
  margin-right: 0.5em;
}

.bulletin-info-item .toc-header > svg {
  margin-left: 0.5em;
}

/*.bulletin-info-item.authors {flex-grow: 1;}*/
.bulletin-info-item {
  padding: 0.5em 1em;
  border-radius: 10em;
  transition: background-color 0.2s;
}

.bulletin-info-item.toc {
  overflow: hidden;
}

.bulletin-info-item.toc .toc-wrap {
  position: relative;
}

.bulletin-info-item.toc:hover {
  overflow: visible;
}

.bulletin-info-item.toc .toc-contents {
  position: absolute;
  width: 300px;
  background: #fff;
  box-shadow: 0 3px 20px gray;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  right: -1rem;
  margin-top: 0.5rem;
  gap: 0.7rem;
  opacity: 0;
  z-index: 1;
}

.bulletin-info-item.toc:hover .toc-contents {
  opacity: 1;
}

.bulletin-img-block {
  padding: 1.5rem;
  background: #fff;
  box-shadow: var(--box-shadow);
  border-radius: 1.5rem;
}

.bulletin-img-block figure {
  text-align: center;
}

.bulletin-img-block figcaption {
  text-align: left;
}

.bulletin-img-block img {
  max-width: 100%;
  border-radius: 1rem;
}

.bulletin-img-block figcaption {
  margin-bottom: -0.5em;
}

.bulletin-img-block figcaption * {
  margin: 0em;
}

.bulletin-section .archive-list-row {
  display: flex;
  gap: 1rem;
  padding: 1rem 2rem;
}

.bulletin-section .archive-list-row > * {
  margin: 0;
}

.bulletin-section .archive-list-row.row-odd {
  background-color: var(--color-darken-5);
}

.bulletin-section .archive-list-row.row-even {
  background-color: var(--color-white-10);
}

.bulletin-section .archive-list-row .archive-title {
  flex-grow: 1;
}

.bulletin-section .table-editorial {
  border-collapse: collapse;
  border: none;
  margin: 0;
}

.bulletin-section .table-editorial tr,
.table-editorial td,
.table-editorial tbody {
  border: none;
  padding: 0;
}

.bulletin-blog-section {
  padding-top: 0 !important;
  padding-bottom: 60px !important;
}

#section-post-content-reference.bg-gray-tile {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.bulletin-share a {
  display: inline-block;
  width: 3rem;
  background: var(--color-rhi-red);
  line-height: 3rem;
  border-radius: 4rem;
  color: #fff;
  font-size: 1.5rem;
  margin: 1rem;
}

.bulletin-section .content.author p,
.bulletin-section .content.author h5 {
  margin: 0;
}

.bulletin-section .content.author {
  display: flex;
  align-items: center;
}

.bulletin-section .content.author .author-img {
  width: 80px;
  border-radius: 100px;
  overflow: hidden;
  margin-right: 10px;
}

.sl-caption.pos-bottom {
  position: relative !important;
}

.bulletin-section .content-wysiwyg h3 {
  font-size: 1.4em;
}

#section-post-content-reference .content p {
  margin: 0;
}

.bulletin-section .content-wysiwyg table.formular {
  width: auto;
}

.bulletin-section .content-wysiwyg table.formular * {
  border: 0;
  background: none !important;
}

.bulletin-section .content-wysiwyg table.formular td {
  width: auto !important;
  padding: 0;
}

.bulletin-section .content-wysiwyg table.formular img {
  max-width: 390px;
}

#section-post-content-reference a {
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  vertical-align: top;
}

@media screen and (max-width: 65rem) {
  .bulletin-blog-preinfo {
    flex-direction: column;
    gap: 0.5em;
  }

  .bulletin-info-item.toc .toc-contents {
    right: auto;
    left: -1em;
  }
}

/*bulletin formulars */
.bulletin-content blockquote,
.bulletin-content blockquote p,
.bulletin-content .bulletin-img-block figcaption em {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  color: var(--color-rhi);
  border: 0;
}

.bulletin-content blockquote {
  margin: 0;
  padding: 0.5em 2.6rem;
}

/* sustainability contents */
.sustainability-section.secbg-gtl,
.sustainability-section.secbg-gtr,
.sustainability-section.secbg-gbl,
.sustainability-section.secbg-gbr,
.na-section.secbg-gtl,
.na-section.secbg-gtr,
.na-section.secbg-gbl,
.na-section.secbg-gbr {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-shade.svg) left top
    no-repeat;
  background-size: 30% auto;
}

.sustainability-section.secbg-gtl,
.na-section.secbg-gtl {
  background-position: -10% 0%;
  background-size: 30% auto;
}

.sustainability-section.secbg-gtr,
.na-section.secbg-gtr {
  background-position: 110% 0%;
  background-size: 30% auto;
}

.sustainability-section.secbg-gbl,
.na-section.secbg-gbl {
  background-position: -10% 100%;
  background-size: 30% auto;
}

.sustainability-section.secbg-gbr,
.na-section.secbg-gbr {
  background-position: 110% 100%;
  background-size: 30% auto;
}

.sustainability-section.secbg-rtl,
.sustainability-section.secbg-rtr,
.sustainability-section.secbg-rbl,
.sustainability-section.secbg-rbr,
.na-section.secbg-rtl,
.na-section.secbg-rtr,
.na-section.secbg-rbl,
.na-section.secbg-rbr {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/rhi-logo-bg-red.svg) left top no-repeat;
  background-size: 40% auto;
}

.sustainability-section.secbg-rtl,
.na-section.secbg-rtl {
  background-position: -10% 0%;
  background-size: 30% auto;
}

.sustainability-section.secbg-rtr,
.na-section.secbg-rtr {
  background-position: 110% 0%;
  background-size: 30% auto;
}

.sustainability-section.secbg-rbl,
.na-section.secbg-rbl {
  background-position: -10% 100%;
  background-size: 30% auto;
}

.sustainability-section.secbg-rbr,
.na-section.secbg-rbr {
  background-position: 110% 100%;
  background-size: 30% auto;
}

.sustainability-section.secbg-spz1 {
  background: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/bush.png) 120% center no-repeat;
  background-size: 40% auto;
}

/* BG North america */
.na-section.secbg-rtl,
.na-section.secbg-rtr,
.na-section.secbg-rbl,
.na-section.secbg-rbr {
  background-image: url(https://www.rhimagnesita.com/wp-content/uploads/2025/01/rhi-logo-bg-firedup.svg);
}

/*.na-section.secbg-rbr {background: url(https://www.rhimagnesita.com/wp-content/uploads/2024/12/get-fired-up-logo-full-colour.svg) left top no-repeat; background-size: 40% auto;}*/
/*.na-section.secbg-gtl,
.na-section.secbg-gtr,
.na-section.secbg-gbl,
.na-section.secbg-gbr {background: url(https://www.rhimagnesita.com/wp-content/uploads/2024/12/get-fired-up-logo-full-gray.svg) left top no-repeat; background-size: 40% auto;}
.na-section.secbg-gtl, .na-section.secbg-rtl {background-position: 0 0;  background-size: 30% auto;}
.na-section.secbg-gtr, .na-section.secbg-rtr {background-position: 100% 0;    background-size: 30% auto;}
.na-section.secbg-gbl, .na-section.secbg-rbl {background-position: 0 calc(100% + 4vw);  background-size: 30% auto;}
.na-section.secbg-gbr, .na-section.secbg-rbr {background-position: 100% calc(100% + 4vw);  background-size: 30% auto;}
.na-hero:after {width: 380px;height: 200px;background: url(https://www.rhimagnesita.com/wp-content/uploads/2024/12/get-fired-up-logo-full-white.svg) center no-repeat;position: absolute;bottom: 40px;right: 0;content: " ";background-size: contain;}
@media screen and (max-width: 65rem) {
	.na-hero:after {width: 100%;height: 150px;}
	section.na-hero .section-bg-image .mobile-slider-image{background-position: 66% top;}

}
*/

.icon-box-grid-cell {
  padding: 2.2em 2em;
  box-shadow: var(--box-shadow);
  background: var(--color-white);
  border-radius: 22px;
}

.icon-box-grid-cell figure.icon-box-grid-icon img {
  height: 230px;
  margin-bottom: 10px;
}

.icon-box-grid-cell .content h1,
.icon-box-grid-cell .content h2,
.icon-box-grid-cell .content h3,
.icon-box-grid-cell .content h4,
.icon-box-grid-cell .content h5 {
  font-weight: 600;
  color: var(--color-rhi);
}

/*.icon-box-grid-cell .content {
    font-weight: 600;   color: var(--color-rhi);}*/

.bg-gray-tile:after,
.full.bg-wrapper.mod-icon-box-grid:after {
  content: ' ';
  display: block;
  background: var(--color-darken-5);
  position: absolute;
  width: 70%;
  height: 60%;
  top: 20%;
  z-index: -1;
  border-radius: 0 4em 4em 0;
}

#section-post-content-reference.bg-gray-tile:after {
  width: 85%;
  height: 100%;
  top: 0;
}

.full.bg-wrapper.mod-icon-box-grid {
  position: relative;
}

.col-gallery-wrap .col-gallery-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
}

.col-gallery-wrap .col-gallery-image {
  padding-top: 100%;
  position: relative;
}

.full-width-gallery-wrap .swiper-slide img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.full-width-gallery-wrap .swiper-slide a {
  display: block;
  height: 100%;
}

.full-width-gallery-wrap .swiper-slide {
  overflow: hidden;
  height: 400px;
}

.mod-image-boxed-bg .image-boxed-bg-wrap {
  margin-top: 15vh;
  padding-bottom: 4em;
  background-size: cover;
  background-position: center;
}

.mod-image-boxed-bg .image-boxed-bg-box {
  background: #fff;
  border-radius: 22px;
  box-shadow: var(--box-shadow);
  padding: 2em;
  position: relative;
  max-width: calc(780px + 4em);
}

.mod-image-boxed-bg .image-boxed-bg-wrap .image-boxed-bg-box {
  top: -15vh;
  margin-bottom: -15vh;
}

.mod-image-boxed-bg .image-boxed-bg-wrap .content {
  color: #fff;
  text-shadow: 0 0 20px black;
}

.page-template-sustainability-page_22 .contact-text {
  font-size: 36px;
  line-height: 1.2em;
}

/*.page-template-sustainability-page_22 #contact {color: #fff;background-color: #555; background-image: url(https://www.rhimagnesita.com/wp-content/uploads/2023/08/rhim-hero-sustainability-planet.jpg); background-size:cover;}
.page-template-sustainability-page_22 #contact a {color: #fff; font-weight: bold;}*/

@media screen and (max-width: 39.9375em) {
  .page-template-sustainability-page_22 #contact {
    font-size: 1.2em;
  }

  #section-post-content-reference.bg-gray-tile:after {
    width: 95%;
  }
}

.contact-industries {
  background-color: var(--color-rhi) !important;
  color: var(--color-white) !important;
  padding: 0;
}

.mod-download-grid.mod-bg-blue {
  background: var(--color-rhi-dark);
  margin: var(--modules-padding-y) 0 calc(var(--modules-padding-y) * -2) 0;
  padding-bottom: calc(var(--modules-padding-y) * 2);
}

.mod-download-grid.mod-bg-blue:last-child {
  margin: var(--modules-padding-y) 0 0 0;
  padding-bottom: var(--modules-padding-y);
}

.mod-download-grid .download-grid-image img {
  width: auto;
  height: 100%;
  border-radius: 1em;
  box-shadow: var(--box-shadow);
}

.mod-download-grid .grid-space-x {
  gap: 1.5rem;
}

.mod-download-grid .download-grid-image {
  margin-bottom: 0.5em;
  height: 280px;
}

.mod-download-grid .download-grid-image.download-image-inline {
  height: 80px;
}

.mod-download-grid .download-grid-cell .content {
  color: var(--color-rhi);
  font-weight: bold;
}

.mod-download-grid .download-grid-cell-text {
  width: 100%;
  max-width: 200px;
}

.mod-download-grid a.file-download {
  transition: filter 0.2s;
}

.mod-download-grid a.file-download:hover {
  filter: brightness(0.5);
}

.mod-download-grid .grid-container.has-bgimg {
  background-size: cover;
  background-position: center;
  padding-top: 5rem;
  padding-bottom: 4rem;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
}

.mod-download-grid .grid-container.has-bgimg:before {
  content: ' ';
  background: #0008;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.mod-download-grid .grid-container.has-bgimg > div {
  position: relative;
}

.mod-download-grid .grid-container.has-bgimg,
.mod-download-grid .grid-container.has-bgimg .content {
  color: #fff;
}

.mod-cta-grid {
  background: var(--color-rhi-red);
}

.mod-cta-grid .content {
  color: #fff;
}

.mod-cta-grid .cta-block {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
}

.mod-cta-grid .cta-grid-image {
  margin: -15% 0;
}

.mod-cta-grid .button-white {
  background: #fff;
  color: var(--color-rhi-red);
}

.mod-cta-grid .button-white:hover {
  border-color: #fff;
  color: #fff;
  background: var(--color-rhi-red);
}

@media screen and (max-width: 39.9375em) {
  .mod-cta-grid .cta-block {
    flex-direction: column;
  }

  .mod-cta-grid .cta-grid-image {
    margin: -15% 0 0 0;
    max-width: 50%;
    float: right;
  }
}

.mod-quotes.quote-container .story-text-wrap {
  padding: 1.5em;
  box-sizing: border-box;
}

.mod-quotes.quote-container .story-text-wrap .content {
  padding: 0.5em 0;
}

.mod-quotes.quote-container .quote-icon {
  width: 1.5em;
}

body.body-dark-section section.section-dark .mod-quotes.quote-container .story-text-wrap * {
  color: var(--color-white);
}

.mod-quotes.quote-container .story-img {
  border-radius: 20px;
  overflow: hidden;
}

.mod-quotes.quote-container .story-img,
.mod-quotes.quote-container .story-img img {
  display: block;
  width: 100%;
}

.mod-quotes.quote-container div[class='swiper-slide'] .story-img {
  max-height: 400px;
}

@media screen and (max-width: 39.9375em) {
  .mod-quotes.quote-container .story-text-wrap {
    width: 85% !important;
  }
}

.image-fw-gray-box img {
  background: var(--color-darken-5);
  padding: 2em;
  border-radius: 2em;
}

.content-yt.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  background: var(--color-darken-5);
  border-radius: 22px;
  overflow: hidden;
}

.content-yt.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content .logoline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.content .logoline > * {
  width: 18%;
  margin: 1% 1% 0 0;
  align-items: center;
}

.content ul.check-list {
  list-style: none;
  margin-left: 1.5em;
}

.content ul.check-list li:before {
  content: ' ';
  width: 1.2em;
  height: 1.2em;
  display: block;
  position: absolute;
  margin: 0.2em 0 0 -1.5em;
  background-image: url(https://www.rhimagnesita.com/wp-content/themes/rhi/img/refresh/icon_check_red.svg);
  background-size: contain;
}

.content ul.check-list li {
  margin-top: 0.3em;
}

/** SDG rows **/
.sdg {
  padding: 15px;
  color: white;
  margin: 2px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sdg-active {
  cursor: help;
}

.sdg-inactive {
  background-color: #e6e6e6 !important;
}

.sdg-num-1 {
  background-color: gray;
}

.sdg-num-2 {
  background-color: gray;
}

.sdg-num-3 {
  background-color: #279b48;
}

.sdg-num-4 {
  background-color: #c31f33;
}

.sdg-num-5 {
  background-color: #ef402b;
}

.sdg-num-6 {
  background-color: gray;
}

.sdg-num-7 {
  background-color: #fcc32b;
}

.sdg-num-8 {
  background-color: #8d1c35;
}

.sdg-num-9 {
  background-color: #f36d25;
}

.sdg-num-10 {
  background-color: gray;
}

.sdg-num-11 {
  background-color: #f89b24;
}

.sdg-num-12 {
  background-color: #cf8d2a;
}

.sdg-num-13 {
  background-color: #48773e;
}

.sdg-num-14 {
  background-color: gray;
}

.sdg-num-15 {
  background-color: #3eb049;
}

.sdg-num-16 {
  background-color: gray;
}

.sdg-num-17 {
  background-color: gray;
}

.sdg .line1 {
  height: 4em;
  display: flex;
}

.sdg .line1 .top {
  text-transform: uppercase;
  font-size: 13px;
  padding-top: 0.5em;
  line-height: 1.1em;
  font-weight: bold;
}

.sdg .line1 .nr {
  font-size: 34px;
  width: 1.2em;
  text-align: center;
  line-height: 1em;
  flex-shrink: 0;
  font-weight: bold;
}

.sdg .line2,
.sdg .line2 .icon-wrap {
  height: 150px;
}

.sdg .line2 {
  position: relative;
}

.sdg .line2 .small {
  position: absolute;
  bottom: 0;
  width: 100%;
  font-size: 14px;
  display: block;
  opacity: 0;
}

.sdg .line2 .icon-wrap {
  position: absolute;
  width: 100%;
  top: 0;
}

.sdg .line2 .icon-wrap .img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sdg.sdg-active:hover .icon-wrap,
.sdg.sdg-active:hover .small {
  transition: all 0.3s;
}

.sdg.sdg-active:hover .icon-wrap {
  opacity: 0.2;
}

.sdg.sdg-active:hover .small {
  opacity: 1;
}

/* form controls */
.wpcf7-form h4 {
  margin: 1em;
}

.wpcf7-form .form-fields > p {
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  justify-content: center;
}

.wpcf7-form .form-fields > p > label {
  width: 48%;
  text-align: left;
}

.wpcf7-form .form-fields {
  max-width: 600px;
  margin: 0 auto;
}

.wpcf7-form .form-fields > p > br,
.wpcf7-form form.sent .wpcf7-response-output {
  display: none;
}

.wpcf7-form .wpcf7-gated-content {
  padding: 2em;
  font-size: 1.5em;
}

@media screen and (max-width: 39.9375em) {
  .wpcf7-form .form-fields > p > label {
    width: 100%;
  }
}
